import { css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import {
    Grid,
    Typography,
    Box,
    Container,
    Button
} from '@material-ui/core';
import {
    CardElevation,
    ButtonDegrade,
    Oferta
} from '@findep/mf-landings-core';
import { navigate } from "../../helpers/queryNavigate"
import FelicidadesApp from '../elements/FelicidadesApp.compilable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { CataloguesService } from '../../services/LandingV4'
import formatNumber from '../../helpers/formatNumber'
import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'

import { withThemeProps } from '@findep/microfronts-core';

import PropTypes from 'prop-types'
import { navigate as gatsbyNavigate } from 'gatsby'
import { truncate } from 'lodash';
import { ModalInfo } from '@findep/mf-landings-core'
import { Snackbar, Backdrop } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import SeguroVida from '../pages/afi/SeguroVida'


const Felicidades = ({ companyName, pageContext, FelicidadesTitle, AppsTitle, TitleButtonEncuesta, TitleButton,
    TitleMonto,
    TitlePago,
    TitlePlazo,
    TitlePrimerPago,
    TitleSucursal,
    txtcomment,
    txtcommentTwo,
    txtcommentThree,
    txtInapp,
    openModal,
    setOpenModal,
    alertMessage,
    titleModal,
    bodyModal
}) => {

    const [open, setOpen] = useState(false)
    const [flujo, setFlujo] = useState({});
    const [survey, setSurvey] = useState('');
    const [congratulations, setCongratulations] = useState('')
    const [nameCompany, setNameCompany] = useState(NameCompany());
    const [fecha, setFecha] = useState('');
    const [mobile, setMobile]= useState();
    const [sizeError, setSizeError] = useState(false);
    const [bdActive, setBdActive]=useState(false);
    const [detectOs, setDetectOs]=useState("DESCKTOP");

    useEffect(() => {
        //Reconocer si es un desktop o movil
       if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
            if(navigator.userAgent.match(/Android/i)){
                // console.log("Estás en un android!!");
                setDetectOs("ANDROID")
            }else{
                // console.log("Estás en un iphone!!");
                setDetectOs("IOS")
            }
            
            setMobile(true)
        } else {
            setMobile(false)
            // console.log("No estás usando un móvil");
        }

        let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let localSurvey = sessionStorage.getItem('survey') || {}
        let congratulaionMessage

        setFlujo(localFlujo)

        async function getdata() {

            const catalogos = new CataloguesService()
            const catData = await catalogos.getSurveys(localFlujo.creditApplicationId)

            if (catData.status === 200) {
                localSurvey = 'true'
                setSurvey(localSurvey)
            }

            const data = await catalogos.getCongratulations(localFlujo.creditApplicationId)
            congratulaionMessage = data.data

            setCongratulations(congratulaionMessage)
            const { forceRedirect } = data.data
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }
        }
        try {
            getdata()
        } catch (e) {
            console.error(e)
        }

        var meses = pageContext.locale === "enUS" ? new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December") : new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
        let f = new Date(localFlujo?.preOferta?.oferta?.nextPayment)
        let arrFecha = `${f.getDate()}-${meses[f.getMonth()]}-${f.getFullYear()}`
        setFecha(arrFecha)
        

    }, [])

    const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
    const TextHeader = css`
    color: #698d21;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
  `
    
    const textMessage = css`
        font-size: 1rem;
    `

    const styleTextEncuesta = css`
    padding: 1rem;
    color: rgba(0, 0, 0, 0.87);
`

    const rootPage = css`
    padding-left: 1rem;
    padding-right: 1rem;
`
const cCenter= css`
    text-align: center;
`
    async function openApp(link){
        setBdActive(true)
        let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        const catalogos = new CataloguesService()
        const data = await catalogos.putAgent(localFlujo.creditApplicationId, mobile)
        // console.log("enviando servicio=>", data)    
        try{
            if(data.status===204){
                if(mobile){
                    window.open(link)
                    setBdActive(false)
                }else{
                    handleModal()
                    setBdActive(false)
                }
            }else{ 
                setSizeError(true)
            }

        }catch (e) {
            console.log("error=>",e)
            setSizeError(true)
        }      
        
    }

    const props = [
        { icono: ArrowForwardIcon, titulo: `${TitleMonto}: `, texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.amount : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: `${TitlePago}: `, texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.payment : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: `${TitlePlazo}: `, texto: flujo ? flujo.preOferta ? `${flujo.preOferta.oferta.terms} ${getPeriodPlural(flujo?.preOferta?.oferta?.period, pageContext.locale === "enUS" ? "en" : "es")} ` : '' : '' },
        { icono: ArrowForwardIcon, titulo: `${TitlePrimerPago}: `, texto: flujo ? flujo.preOferta ? `${fecha}` : '' : '' },
    ]

    const propsSucursal = [
        { icono: ArrowForwardIcon, titulo: `${TitleSucursal}: `, texto: `${congratulations?.branch?.name}  ${congratulations?.branch?.city ? congratulations?.branch?.city : ''} Col. ${congratulations?.branch?.neighborhood} ${congratulations?.branch?.address} ${congratulations?.branch?.postalCode} ${congratulations?.branch?.state}`, sizeFontLi: '14px' },
    ]
    const handleModal = () => {
        openModal ? setOpenModal(!openModal) : setOpen(!open)
    }
  
    

    return (
        <Container justify="center" maxWidth="sm">
            <CardElevation>
            <Grid container direction="column" justify="center" alignItems="center" css={rootPage}>                
                
                <Typography color='primary'>
                    <h1 css={TextHeader}>{FelicidadesTitle}</h1>
                </Typography>
                <Typography >
                   <div css={cCenter}> <strong>{txtcomment}</strong> </div>
                </Typography>
                <br />    
                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />
                <br />
                {
                        congratulations.branch ? (
                            <Typography css={styleTextEncuesta}>                    
                                <p css={textMessage}>&nbsp;</p>
                                <br /><br />
                                <Oferta datos={propsSucursal} colorOne="#FF9F01" colorTwo="#FA4616" />       
                            </Typography>                
                        ) : null
                }
                
                <br />
                <Typography >
                   <div css={cCenter}> {txtcommentTwo} </div>
                </Typography>
                <br/>
                <FelicidadesApp  detectedIos={detectOs} company={companyName} links={congratulations?.shortLink} mobile={mobile} handleModal={()=>handleModal()} setSizeError={()=>setSizeError(false)}/>
                <br/>
                <Typography >
                   <div css={cCenter}> {txtcommentThree} </div>
                </Typography>
                <br/> 
                <SeguroVida entrada="felicidades" pageContext={pageContext}/>
                <br/>
                {/* <a href={congratulations?.shortLink} target="_blank" rel="noopener noreferrer"  > */}
                    
                {/* </a> */}

                <a                     
                    href={mobile ?  congratulations?.shortLink : 'javascript:void(0)'}
                    target="_parent" 
                    rel="noopener noreferrer" 
                    onClick={()=> openApp(congratulations?.shortLink)} 
                    css={css`text-decoration: none;`}      
                >
                    <ButtonDegrade textButton={txtInapp} disabled={bdActive}/>        
                </a>
                
                <br/>
                
                {/* <br/>
                <Typography css={styleTextEncuesta} align="center">
                    {TitleButtonEncuesta}
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        style={{
                            borderRadius: 35,
                            background: "linear-gradient(#AAA7A7, #3F3D3D)",                            
                            fontSize: "16px",
                            color:"white",
                            fontWeight:"bold"
                        }}
                        variant="extended"
                        onClick={() => navigate(pageContext.next.path)}                        
                        >
                        {TitleButton}
                    </Button>                    
                </Box>                 */}
                <br />
            </Grid>
            </CardElevation>
            <ModalInfo
                onClose={() => handleModal()}
                title={titleModal}
                buttonJustify='flex-end'
                color='primary'
                open={openModal || open}
                // open={true}
                btnLeft={null}
                body={bodyModal}
                btnRight="Cerrar"
                btnRightColor='primary'
                onChange={(data) => handleModal()}
                rootCss='
                @media(min-width: 768px){
                    width: 50%!important;
                    height: auto!import;                            
                }
                @media(min-width: 1024px){
                    width: 40%!important; 
                    height: auto!import;                           
                }
                @media(min-width: 1440px){
                    width: 35%!important;   
                    height: auto!import;                     
                }'
            />
            <Backdrop css={bDrop} open={bdActive} >
                <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar open={sizeError} autoHideDuration={6000} onClose={() => setSizeError(false)}>
                <Alert
                variant="filled"
                severity="warning"
                onClose={() => setSizeError(false)}
                >
                {alertMessage}
                </Alert>
            </Snackbar>
        </Container>

    )
}

export default withThemeProps(Felicidades, 'VDPNFelicidades')

Felicidades.propTypes = {
    FelicidadesTitle: PropTypes.string,
    AppsTitle: PropTypes.string,
    TitleButton: PropTypes.string,
    openModal: PropTypes.bool,
}

Felicidades.defaultProps = {
    txtInapp:"Entrar a la App Móvil.", 
    txtcommentThree:<span><strong>PASO 2.- Da click en el siguiente botón para ingresar a la aplicación móvil</strong><br/>La App te guiará paso a paso a finalizar el proceso del préstamo.</span>,
    txtcommentTwo:<span><strong>PASO 1.- Descarga nuestra aplicación móvil</strong><br/>Da clic en el botón  que corresponda al tipo de teléfono celular que tienes.</span>,
    txtcomment:"Estamos por finalizar el proceso. Ahora procederemos a descargar la aplicación móvil para que recibas tu préstamo.",
    FelicidadesTitle: "REGISTRATE EN LA APP MÓVIL",
    AppsTitle: "¡Ahorra tiempo! Maneja tu cuenta en donde te encuentres. ¡Descarga nuestra aplicación hoy!",
    TitleButtonEncuesta: "¿Nos ayudarías a contestar una breve encuesta para mejorar nuestro servicio?",
    TitleButton: "CONTESTAR ENCUESTA",
    TitleMonto: "Monto",
    TitlePago: "Pago",
    TitlePlazo: "Plazo",
    TitlePrimerPago: "Primer pago",
    TitleSucursal: "Sucursal",
    alertMessage: "Lo sentimos, por el momento el servicio no se encuentra disponible",
    titleModal:"Ya estás muy cerca!",
    bodyModal: <span>Se te ha enviado un SMS a tu celular para que puedas descargar la app y seguir con el proceso de prestamo que tienes con nosotros.</span>


}